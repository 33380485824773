import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

import { Container, Row, Col } from "react-bootstrap"

const SecondPage = () => (
  <Layout>
    <Seo title="Commercial Electrician" />
    <h1>Commercial Electrician</h1>
    <h2>Commercial Electrical Services</h2>
    <p>When you want an experienced commercial electrician, Thomas Tripolt of All Electric can provide for all of your needs. Thomas has been serving business owners throughout Marin County with the reliability and integrity of European craftsmanship since 1984. Rather than a member of the All Electric team, you will always work directly with Thomas, ensuring that you will receive the quality results you expect.</p>
    <p className="text-center">
      <StaticImage
        src="../../images/commercial.jpg"
        width={600}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Commercial Electrical Box"      
      />
    </p>
    <Container>
      <Row>
        <Col lg={6}>
          <ul>
            <li>Remodeling/Reconfiguration</li>
            <li>Lighting design and installation</li>
            <li>Expert troubleshooting</li>
            <li>Sub metering</li>
            <li>Phone and network wiring</li>
            <li>Tenant improvements</li>
            <li>Lighting modifications</li>
            <li>Access Control</li>
            <li>Intercom installation</li>
            <li>Main power upgrades</li>
          </ul>
        </Col>
        <Col lg={6}>
          <ul>
            <li>Ballast and lamp replacement</li>
            <li>Energy management</li>
            <li>Panel and breaker upgrades</li>
            <li>Rewiring projects</li>
            <li>Maintenance contracts</li>
            <li>Energy efficient LED lighting</li>
            <li>Additional circuits and outlets</li>
            <li>Emergency generators</li>
            <li>And much more!</li>
          </ul>
        </Col>
      </Row>
    </Container>
    
  </Layout>
)

export default SecondPage
